// Account.tsx

import React from 'react';
import { useUser, useAuth } from '@clerk/clerk-react';

const Account: React.FC = () => {
  const { isSignedIn, signOut } = useAuth();
  const { user, isLoaded } = useUser();

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  if (!isSignedIn || !user) {
    return <div>User is not authenticated.</div>;
  }

  return (
    <div>
      <h1>Dashboard</h1>
      <p>Welcome, {user.firstName} {user.lastName}!</p>
      <p>Your email: {user.primaryEmailAddress?.emailAddress}</p>
      <button onClick={() => signOut()}>Sign Out</button>
    </div>
  );
};

export default Account;
