// src/pages/Home.tsx
import React from 'react';
import { SignedIn, SignedOut, UserButton } from '@clerk/clerk-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HomeContainer = styled.div`
  min-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding-top: 2rem;
`;

const Home: React.FC = () => (
  <HomeContainer>
    <h1>Welcome to My App</h1>

    <SignedIn>
      <UserButton />
      <p>You are signed in.</p>
      <Link to="/account">Go to Account</Link>
    </SignedIn>

    <SignedOut>
      <p>You are not signed in.</p>
      <Link to="/sign-in">Sign In</Link> or <Link to="/sign-up">Sign Up</Link>
    </SignedOut>
  </HomeContainer>
);

export default Home;
