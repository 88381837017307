// App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Help from './pages/Help';
import Account from './pages/Account';
import SignInPage from './components/Auth/SignIn';
import SignUpPage from './components/Auth/SignUp';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import Footer from './components/Footer';

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/help" element={<Help />} />
          <Route path="/sign-in/*" element={<SignInPage />} />
          <Route path="/sign-up/*" element={<SignUpPage />} />

      {/* Protected Routes */}
      <Route
        path="/account"
        element={
          <ProtectedRoute>
            <Account />
          </ProtectedRoute>
        }
      />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
